import { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
import { useAuthState } from '../../../Context';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./css/slideshow.css";
// import "./styles.css";


// import Swiper core and required modules
import SwiperCore, {
    EffectFade, Navigation, Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectFade, Navigation, Pagination]);

const SlideShow = () => {

    const [auth, setAuth] = useState()
    const userDetails = useAuthState()


    useEffect(() => {
        if (userDetails.auth_token === "" && userDetails.userDetails === "") { // Means the user is Unauthenticated
            setAuth(false)
        } else if (userDetails.auth_token !== "" && userDetails.userDetails !== "") { // Means the user is Authenticated
            setAuth(true);
        }
    }, [auth]);

    return (
        <div id="slideShoww">
            <Swiper
                effect={'fade'}
                navigation={true}
                autoplay={{
                    delay: 2500,
                }}
                pagination={{
                    "clickable": true
                }}
                className="mySwiper">
                <SwiperSlide>
                    <div className="overlay">
                        <h1 className="animeh1">K Plus Classes</h1>
                        <p>We can and we will.</p>
                        {auth ?
                            <div className="btn-container">
                                <button className="btn mx-2" onClick={() => window.location.href = '/login'}>Free Resources</button>
                            </div>
                            :
                            <div className="btn-container">
                                <button className="btn mx-2"><Link to={'/login'}>Login</Link></button>
                                <button className="btn mx-3"><Link to={'/signup'}>Signup</Link></button>
                            </div>
                        }
                    </div>
                    <img src="https://wallpapercave.com/wp/wp1888102.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="overlay" >
                        <div className="overlay">
                            <h1 className="animeh1">K25 Classes</h1>
                            <p>Our new Mentor and Mentorship Programme.</p>
                            <div className="btn-container">
                                <button className="btn mx-3"> <Link to={'/coming-soon'}>Know More</Link></button>
                            </div>
                        </div>
                    </div>
                    <img src="https://images.unsplash.com/photo-1588075592446-265fd1e6e76f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="overlay" >
                        <h1 className="animeh1">Krishna Foundation</h1>
                        <p>
                            Our social work wing.
                        </p>
                        <div className="btn-container">
                            <button className="btn mx-3"> <Link to={'/coming-soon'}>Know More</Link></button>
                        </div>
                    </div>
                    <img src="https://wallpapercave.com/wp/wp1888109.jpg" />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}
export default SlideShow;