import { useEffect } from 'react';
import AOS from 'aos';
import './css/HPS4.css';
import ModesOfTeachingCards from './ModesOfTeachingCards';

const HPS4 = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <section id="shps4">
            <div className="container hps4" id="hps4">
                <h1 data-aos="fade-up" data-aos-duration="800">Our Modes of Teaching</h1>
                <article data-aos="fade-up" data-aos-duration="800">
                    Our modes of teaching are quite diverse we conduct both offline classes &amp; online classes, we give home tuitions and recorded video lectures. 
                    You can buy our courses which are coming soon and you can check out the class videos which are available for free in the free resources section.
                </article>
                <div className="content">
                    <div className="hideOverflowOnPhone">
                        <ModesOfTeachingCards title="Offline Classes" img="./assets/offline.png" dataAOS="fade-right" content="Currently we serve in Kolkata at two centers- Dumdum, Khardha but a student can anytime pick a course and would get offline visiting faculties for inspection." />
                        <ModesOfTeachingCards title="Online Live Classes" img="./assets/online.png" dataAOS="fade-left" content="We mentor students by providing live classes (online) via different platforms." />
                    </div>
                    <div className="hideOverflowOnPhone">
                        <ModesOfTeachingCards title="Home Tuitions" img="./assets/home.png" dataAOS="fade-right" content="We provide home tuitons for students who want to study peacefully in their respective homes." />
                        <ModesOfTeachingCards title="Recorded Lectures" img="./assets/video.png" dataAOS="fade-left" content="We provide recorded lectures so that you can study anywhere anytime without any disruption." />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HPS4
