import { Link } from 'react-router-dom';
import './footer.css';
const Footer = () => {
	return <>
		<div className="footer">
			<div className="footerChild">
				<h1>K Plus</h1>
				<p>
				K Plus is an educational institution established on 2nd April 2020 on an online basis. It started its first center on 24th January 2021 in Kolkata and gradually it expanded all over the country even overseas.
				</p>
				{/* <p>CIN : 546879546879546879546865</p> */}
			</div>
			<div className="footerChild">
				<h4>Connect with us</h4>
				<a href="https://www.facebook.com/Kplusclasses/"><i className="fab fa-facebook-f" /> Facebook</a>
				<a href="https://instagram.com/k_plus_classes?igshid=1jaqmxs1jqouz"><i className="fab fa-instagram" /> Instagram</a>
				<a href="https://www.youtube.com/channel/UCI49clIFbJl74x_7P7QvTZA"><i className="fab fa-youtube" /> Youtube</a>
				<a href="https://www.linkedin.com/in/knowledge-plus-05653a200"><i className="fab fa-linkedin-in" /> Linkedin</a>
			</div>
			<div className="footerChild">
				<h4>Quick Links</h4>
				<Link to={"/free-resources"} >Free Resources</Link>
				<Link to={"/coming-soon"} >Courses</Link>
				<Link to={"/coming-soon"} >Careers</Link>
				<Link to={"/coming-soon"} >Krishna Foundation</Link>
			</div>
			<div className="footerChild">
				<h4>Contact Us</h4>
				<a href="mailto:sweta@kplusclasses.com"><i className="fa fa-envelope" />  sweta@kplusclasses.com</a>
				<a href="tel:9883148816"><i className="fa fa-phone-alt" /> +91 98831 48816</a>
				<a href="https://g.co/kgs/QZYMnS"><i className="fa fa-map-marker-alt" /> Kolkata, West Bengal, India.</a>
			</div>
		</div>
		<div className="bot-footer">
			<span>&copy;K Plus 2022 Private Limited</span>
			<span>
				<a href="/privacy-policy">Privacy Policy</a>
				&nbsp;
				&nbsp;
				|
				&nbsp;
				&nbsp;
				<a href="/terms-and-conditions">Terms and Conditions</a>
			</span>

		</div>
	</>
};

export default Footer;
