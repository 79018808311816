import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"
import SwiperCore, {
    EffectCoverflow, Pagination
} from 'swiper';
import './css/gallery.css';

SwiperCore.use([EffectCoverflow, Pagination]);

const demoData = [
    {
        "img": "https://swiperjs.com/demos/images/nature-1.jpg",
        "title": "This is so awesome"
    },
    {
        "img": "https://swiperjs.com/demos/images/nature-2.jpg",
        "title": "This is so awesome"
    },
    {
        "img": "https://swiperjs.com/demos/images/nature-3.jpg",
        "title": "This is so awesome"
    },
    {
        "img": "https://swiperjs.com/demos/images/nature-4.jpg",
        "title": "This is so awesome"
    },
    {
        "img": "https://swiperjs.com/demos/images/nature-5.jpg",
        "title": "This is so awesome"
    },
    {
        "img": "https://swiperjs.com/demos/images/nature-6.jpg",
        "title": "This is so awesome"
    },
    {
        "img": "https://swiperjs.com/demos/images/nature-8.jpg",
        "title": "This is so awesome"
    },
    {
        "img": "https://swiperjs.com/demos/images/nature-8.jpg",
        "title": "This is so awesome"
    },
]

const Gallery = () => {
    return <>
        <section className="gallerys">
            <div className="container" id="gallery">
                <h1>Gallery</h1>
                <Swiper 
                    effect={'coverflow'} 
                    grabCursor={true} 
                    centeredSlides={true} 
                    slidesPerView={'auto'} 
                    coverflowEffect={{
                        "rotate": 50,
                        "stretch": 0,
                        "depth": 100,
                        "modifier": 1,
                        "slideShadows": true
                    }} 
                    pagination={true}
                    autoplay = {{
                        delay: 1000,
                    }}
                    id="galCont"
                >
                {demoData.map((data,index)=>{
                    return (
                        <SwiperSlide>
                            <img src={require(`./assets/gallery/${index+1}.jpeg`)} />
                            {/* <span>{data.title}</span> */}
                        </SwiperSlide>
                    );
                })}
                </Swiper>
            </div>
        </section>
    </>;
};

export default Gallery;
