import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ComingSoon = () => {
    return (<>
        <Navbar />
        <div className="hero-res" style={{minHeight:'80vh'}}>
            <div className="overlay">
                <h1>Coming Soon</h1>
            </div>
        </div>
        <Footer />
    </>);
};

export default ComingSoon;
