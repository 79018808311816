import React, { useState, useEffect } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import { useAuthState } from '../../../Context';

const Navbar = () => {
	const [showNavbar, setShowNavbar] = useState(false)
	const [auth, setAuth] = useState()
	const userDetails = useAuthState()

	useEffect(() => {
		if (userDetails.auth_token === "" && userDetails.userDetails === "") { // Means the user is Unauthenticated
			setAuth(false)
		} else if (userDetails.auth_token !== "" && userDetails.userDetails !== "") { // Means the user is Authenticated
			setAuth(true);
		}
	}, [auth]);


	console.log(userDetails)
	return (
		<div className={`k-navbar container k-navbar-abs ${showNavbar ? 'transWhite' : ''}`} id="kNavbar">
			<div className="left d-flex cp" onClick={() => window.location.href = '/'}>
				<img className='logo' src={require("../../../assets/logo.jpg")} alt="kplus logo" />
				<h1 className={showNavbar ? 'dark-text' : ''} >K PLUS</h1>
			</div>
			<div className={`right NONE ${showNavbar ? 'd-flex container' : 'NONE'}`}>
				<Link to={'/#aboutus'}>About Us</Link>
				<Link to={'/free-resources'}>Free Resources</Link>
				<a href="/coming-soon">Courses</a>
				{auth ? <>
					{/* <img className="cp" src="https://i.pinimg.com/originals/4d/12/3d/4d123d3e1ea204c532fc7de1a5827e0b.jpg" alt={userDetails.userDetails.first_name + userDetails.userDetails.last_name} /> */}
					<Link className="mx-2" to={'/'}>Hi  {userDetails.userDetails.first_name} {userDetails.userDetails.last_name} </Link>
				</>
					:
					<div className={`d-flex ${showNavbar} ? 'd-flex' : ''`}>
						<Link to="/login" className="btn login text-white">Log In</Link>
						<Link to="/signup" className="btn signup">Sign Up</Link>
					</div>
				}
			</div>
			<button className='btn' onClick={() => setShowNavbar(!showNavbar)}>
				<i className={`fa fa-bars ${showNavbar ? 'text-dark fa-times' : 'text-white'}`}></i>
			</button>
		</div>
	)
}

export default Navbar
