import { useEffect } from 'react';
import AOS from 'aos';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/navigation"
import './css/HPS5.css'

SwiperCore.use([Autoplay, Navigation]);

const HPS5 = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const DummyArray = [
    {
      "img": "./assets/testimonials/ashwani.PNG",
      "name": "Ashwani Dubey",
      "desgination": "Company Law",
      "testimonial": "I started learning from sweta di during the period of covid from 23rd June, 2020. And she is the onewho clears every doubts of the students to the full extent. She teaches in a fabulous way so that thestudents can understand to his/her best."
    },
    {
      "img": "./assets/testimonials/adrika.PNG",
      "name": "Anjali Dubey",
      "desgination": "Higher Secondary",
      "testimonial": "I had a great experience and learned so many things in K PLUS. Studying Law, Economics and other corporate structure subjects from the teachers of K PLUS in general and Sweta ma’am in particular is a treat.In my foundation I had a great time scoring an aggregate of 89% Thank you team KPLUS."
    },
    {
      "img": "./assets/testimonials/anjali.PNG",
      "name": "Adrika Dasgupta",
      "desgination": "Class 12 Commerce",
      "testimonial": " My journey with this institution has been amazing from the very first day. The teachers working here are very hard working and generous. My journey with the institution has been great. The institution provides us with a lot of facilities. The teachers are really cooperative and generous. They work really hard to provide us with the best."
    },
    {
      "img": "./assets/testimonials/madhurima.PNG",
      "name": "Madhurima Saha",
      "desgination": "ISC Class 12th",
      "testimonial": " It&#39;s been an year since I started studying under Sweta ma’am and it&#39;s been wonderful . With her new techniques of teaching and her commendable ways of explaining makes our topics even more simplified and easier to learn. Nobody will ever regret studying under her guidance."
    },
    {
      "img": "./assets/testimonials/om.PNG",
      "name": "Om Shaw",
      "desgination": "Class 9",
      "testimonial": "I Have studied from Sweta Di for 7 months and my experience was great and her explaining skills are also great."
    },
  ]
  return (
    <section className="shps5">
      <div className="container hps5" id="hps5">
        <div className="info-container">
          <h1 data-aos="fade-up" data-aos-duration="600">Testimonials</h1>
          <article data-aos="fade-up" data-aos-duration="600">
            "Action speaks louder than words" this phrase is also true for our students who have proved themselves by scoring the best marks in their class and board examinations, and not only that they are able to qualify for competitive exams too as their basics are clear.
          </article>
        </div>
        <div className="swiper-container" data-aos="fade-left" data-aos-duration="600">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{
              delay: 3500,
              waitForTransition: true,
              disableOnInteraction: false,
            }}
            centeredSlides={true}
            keyboard={{
              "enabled": true
            }}
            grabCursor={true}

            navigation={true}
            className="mySwiper"
          >

            {DummyArray.map((data,index) => {
              return (
              <SwiperSlide key={index}>
                <div className="slide">
                  <div className="img-container">
                    <img src={require(`${data.img}`)} alt='' />
                  </div>
                  <div className="details-container">
                    <h4>
                      {data.name}
                    </h4>
                    <h6>
                      {data.desgination}
                    </h6>
                    <article>
                      {data.testimonial}
                    </article>
                  </div>
                </div>
              </SwiperSlide>
              );
            })}

          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default HPS5;