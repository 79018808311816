import { useEffect } from "react";
import AOS from "aos";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards"
import SwiperCore, { EffectCards } from 'swiper';
import './css/HPS6.css' ;

SwiperCore.use([EffectCards]);

const HPS6 = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    const DummyArray = [
        {
            "img": "./assets/events/donation.jpeg",
            "eventName": "Lorem, ipsum."
        },
        {
            "img": "./assets/events/pub_spe_work.jpeg",
            "eventName": "Lorem, ipsum."
        },
        {
            "img": "./assets/events/public_speaking_workshop.jpeg",
            "eventName": "Lorem, ipsum."
        },
        {
            "img": "./assets/events/vadvivaad.jpeg",
            "eventName": "Lorem, ipsum."
        },
        {
            "img": "./assets/events/webinar_perfect_score.jpeg",
            "eventName": "Lorem, ipsum."
        },
    ]
    return (
        <section className="shsc6">
            <div className="container hsc6" id="hsc6">
                <div className="event-info-container">
                    <h1 data-aos="fade-up" data-aos-duration="600">Our Events</h1>
                    <article  data-aos="fade-up" data-aos-duration="600">
                        We conduct a plethora of events so that our students are engaged in every manner. This ensures that our students are not bored by the monotonous way of life and this also increases the general knowledge of our students. We believe in holistic learning and overall development of our students.
                    </article>
                </div>
                <div className="event-cards-container"  data-aos="fade-left" data-aos-duration="700">
                    <Swiper 
                        effect={'cards'}
                        grabCursor={true}
                    >
                        {DummyArray.map((data,index) =>{
                            return(
                                <SwiperSlide key={index}>
                                    <img src={require(`${data["img"]}`)} alt={'K plus' + data["eventName"]} />
                                    {/* <h4>{data["eventName"]}</h4> */}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default HPS6
