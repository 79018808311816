import { useEffect } from 'react';
import AOS from 'aos';

const Accordion = (props) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className="accordion-item" data-aos="fade-up" data-aos-duration="600">
            <h2 className="accordion-header" id={`flush-heading${props.id}`} >
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${props.id}`} aria-expanded="false" aria-controls={`flush-collapse${props.id}`}>
                    <span className="badge">{props.id}</span> {props.question}
                </button>
            </h2>
            <div id={`flush-collapse${props.id}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${props.id}`} data-bs-parent="#faq">
                <div className="accordion-body">
                    {props.answer}
                </div>
            </div>
        </div>
    )
}

export default Accordion
