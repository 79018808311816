import { useEffect } from 'react';
import AOS from 'aos';
import Questions from './questions';
import Accordion from './Accordion';
import './css/Faq.css';

const Faq = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <section className="FaqSection">
            <div className="FaqContainer container" id="FaqContainer">
                <h1 data-aos="fade-up" data-aos-duration="600"><span>Frequently</span> Asked Questions</h1>
{/* 
                <div data-aos="fade-up" data-aos-duration="800" className="video-container d-flex justify-content-center align-items-center py-5">
                </div> */}

                <div className="main">
                    <div className="accordion accordion-flush" id="faq">
                        {Questions.map((data,index) =>{
                            return <Accordion key={index} id={index+1} question={data["question"]} answer={data["answer"]} />
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq
