import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import validator from 'validator';
import { loginUser, useAuthState, useAuthDispatch } from '../../Context/index' 
import { Link } from 'react-router-dom';

import '../Signup/styles.css';
import './styles.css';


const Signup = (props) => {

    const dispatch = useAuthDispatch();     // Read Dispatch method from context
    const user = useAuthState()
    const { loading, errorMessage } = useAuthState();

    const [showPass, setShowPass] = useState(false);

    // For the input fields
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState('');
    const [password, setPass] = useState('')
    const [passValid, setPassValid] = useState(false)

    // For preventing double click on the submit button
    const [buttonVisible, setButtonVisible] = useState(true)

    const options = {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
    }
    useEffect(() => {
        if (user.auth_token !== "" && user.userDetails !== "") {
            props.history.push('/')
        }
        if (validator.isEmail(email) === true) {
            setEmailValid(true);
        }
        if (validator.isEmail(email) === false) {
            setEmailValid(false);
        }
        if (validator.isStrongPassword(password, { options: options }) === true) {
            setPassValid(true);
        } else {
            setPassValid(false);
        }
    }, [email, password])

    const formSubmit = () => {
        if(email === '' || password === ''){
            if (password === '') {
                Swal.fire(
                    'Empty Field',
                    'Password field empty',
                    'warning'
                );
            }
            if(email === ''){
                Swal.fire(
                    'Empty Field',
                    'Email/Phone number field empty',
                    'warning'
                );
            }
            return
        }
        if(passValid === false){
            Swal.fire(
                'Incorrect Password',
                'Please check the password you have entered',
                'warning'
            );
        }
        if (emailValid === false) {
            Swal.fire(
                'Incorrect Email',
                'Please check the email you have entered',
                'warning'
            );
        }
        if (emailValid === true && passValid === true) {
            // setButtonVisible(false);
            handleLogin();
            // axios({
            //     method: 'POST',
            //     url: 'http://localhost:8000/auth/login-email',
            //     data: {
            //         "email": email,
            //         "password": password,
            //     }
            // }).then(res => {
            //     console.log(res.data)
            //     setButtonVisible(true);
            //     if (res.data.token !== undefined) {
            //         window.localStorage.setItem('token', res.data.token)
            //     }
            //     if (res.data.error !== undefined) {
            //         Swal.fire(
            //             `Error Occured`,
            //             `${res.data.error}`,
            //             'error'
            //         )
            //         setButtonVisible(true);
            //     }
            // }).catch(err => {
            //     console.log(err.status);
            //     console.log(err);
            //     Swal.fire(
            //         'Error Occured',
            //         `${err}`,
            //         'error'
            //     )
            }
    }
    
    const handleLogin = async (e) => {
        let payload = {email,password}
        try {
            let response = await loginUser(dispatch, payload) //loginUser action makes the request and handles all the neccessary state changes
            console.log('response from action =>  ',response)
            if (response.auth_token !== undefined && response.user !== undefined) {
                Swal.fire({
                    title: 'Successfully Logged In',
                    text: `Welcome ${response.user.first_name} ${response.user.last_name}`,
                    icon: 'success',
                }).then(res =>{
                    if(res.dismiss || res.isConfirmed || res.isDismissed){
                        window.location.href = "/";
                        // idk why the state of other comps are not getting updated so finally i have to do this
                        // this may not be the best way but atleast this works
                        // we have to use this two times when logging in and when logging out
                    }
                }).catch(err =>{
                    console.error(err)
                })
            }
            if(response.user === undefined){
                Swal.fire(
                    `Error Occured`,
                    `${response}`,
                    'error'
                )
                return
            }

        } catch (err) {
            console.log('error from loginUser.js =>  ',err);
            Swal.fire(
                'Error Occured',
                `${err}`,
                'error'
            )
        }
    }

    return (
        <section id="signup">
            <div className="left">
                <Link className="top-link" to="/"><i className="fas fa-arrow-left"></i>Home</Link>
                <div className="auth-container">
                    <div className="auth-card login-form">
                        <h3 className="text-center">Login to <span className="blokk">K Plus</span></h3>
                        <br />
                        <div className="imput">
                            <span>Email</span>
                            <div className="left-icon">
                                <i className="fas fa-phone-alt"></i>
                                <input className="form-control" name="email_or_phone" type="text" placeholder="Enter your email address"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="imput">
                            <span>Password</span>
                            <div className="right-icon">
                                <i className={`fa fa ${showPass ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPass(!showPass)}></i>
                                <input className="form-control" name="password" type={showPass ? 'text' : 'password'} placeholder="Enter password"
                                    onChange={(e) => setPass(e.target.value)}
                                />
                            </div>
                        </div>
                        {!loading ?
                            <button className="btn w-100 text-white mb-2" id="submit-signup" style={{ background: '#66f' }} onClick={formSubmit}>Login</button>
                            :
                            <button className="btn w-100 text-white mb-2" id="submit-signup" style={{ background: 'rgba(164, 164, 255, 0.95)' }} onClick={() => console.log('Wait Bithc')}>
                                <div className="spinner-border text-white" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        }
                    </div>
                </div>
                <div className="signup-footer py-2">
                    <Link to={'/privacy-policy'}>Privacy Policy</Link>
                    <Link to={'/terms-and-conditions'}>Terms and Conditions</Link>
                </div>
            </div>
            <div className="right changedBG">
                <div className="overlay">
                    <div className="hero">
                        <img src={require('../../assets/logo.jpg')} alt="K Plus logo" />
                        <h1>K Plus</h1>
                        <p>
                        K Plus is an educational institution established on 2nd April 2020 on an online basis. It started its first center on 24th January 2021 in Kolkata and gradually it expanded all over the country even overseas.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Signup
