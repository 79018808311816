require('./css/HPS1.css')
  
const HPS1 = () => {
    return (
        <div className='container hsc1' id="hsc1">
            <div className="left">
                    <span>K PLUS</span>
                    <h1>India's most famous Educational platform.</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt eveniet culpa cumque soluta omnis adipisci cum eligendi neque atque rem!</p>
                    <div className="btn-container">
                        <a href="/">Register Now</a>
                        <a className='mx-4' href="/">Free Resources</a>
                    </div>
            </div>
            <div className="right">
				<img src={require('./assets/homepage1.png')} alt="kplus logo" />
            </div>
        </div>
    )
}

export default HPS1
