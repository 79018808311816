import { useState, useEffect } from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import validator from 'validator';
import './styles.css';
import { Link } from 'react-router-dom';
import { useAuthState } from "../../Context";

const Signup = ( props ) => {
    const user = useAuthState()

    const [index, setIndex] = useState(true);
    const [showPass, setShowPass] = useState(false);
    const [showCpass, setShowCpass] = useState(false);

    // For the input fields
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [dob, setDob] = useState('')
    const [email, setEmail] = useState('')
    const [eValid, setEValid] = useState(false)
    const [phone, setPhone] = useState('')
    const [ePhoneValid, setPhoneValid] = useState(false)
    const [pass, setPass] = useState('')
    const [passMatch, setpassMatch] = useState(false)
    const [ePassValid, setPassValid] = useState(false)
    const [cpass, setCpass] = useState('')
    const [address,setAddress] = useState('')

    // For preventing double click on the submit button
    const [buttonVisible, setButtonVisible] = useState(true)

    const options = {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
    }
    useEffect(() => {
        if (user.auth_token !== "" && user.userDetails !== "") {
            props.history.push('/')
        }
        if (phone.length < 10 || phone.length > 10) {
            setPhoneValid(false)
        } else {
            setPhoneValid(true)
        }
        if (validator.isEmail(email) === true) {
            setEValid(true);
        }
        if (validator.isEmail(email) === false) {
            setEValid(false);
        }
        if (pass === cpass) {
            setpassMatch(true);
            if (validator.isStrongPassword(pass, { options: options }) === true) {
                setPassValid(true);
            } else {
                setPassValid(false);
            }
        } else {
            setpassMatch(true);
        }
        if (pass !== cpass) {
            setPassValid(false);
        }
    }, [email, eValid, phone, ePhoneValid, pass, passMatch, ePassValid, cpass,])

    const formSubmit = () => {
        // Validation starts here

        // 1. Empty input validation
        if (fname === '' || lname === '' || dob === '' || email === '' || phone === '' || pass === '' || cpass === '' || address === '') {
            if (fname === '') {
                Swal.fire(
                    'First Name field empty',
                    'Please write your first name',
                    'warning'
                );
                setIndex(true);
                return
            }
            if (lname === '') {
                Swal.fire(
                    'Last Name field empty',
                    'Please write your last name',
                    'warning'
                );
                setIndex(true);
                return
            }
            if (dob === '') {
                Swal.fire(
                    'Date of birth field empty',
                    'Please write your Date of birth',
                    'warning'
                );
                setIndex(true);
                return
            }
            if(address === ''){
                Swal.fire(
                    'Address field empty',
                    'Please enter your address',
                    'warning'
                )
                return
            }
            if (email === '') {
                Swal.fire(
                    'Email field empty',
                    'Please write your email',
                    'warning'
                )
                return
            }
            if (phone === '') {
                Swal.fire(
                    'Phone no. field empty',
                    'Please enter your phone no.',
                    'warning'
                )
                return
            }
            if (pass === '') {
                Swal.fire(
                    'Password field empty',
                    'Please enter your password',
                    'warning'
                )
                return
            }
            if (cpass === '') {
                Swal.fire(
                    'Confirm Password field empty',
                    'Please enter your confirmed password',
                    'warning'
                )
                return
            }
        }
        // 2. Validate Phone no. Email and Password
        if (validator.isEmail(email) === false) {
            Swal.fire(
                'Invalid Email',
                'You entered an invalid email try entering a valid email and try again',
                'warning'
            );
        }
        if (ePhoneValid === false) {
            Swal.fire(
                'Invalid Phone Number',
                'Entered phone number is invalid',
                'warning'
            )
        }
        if (pass !== cpass) {
            Swal.fire(
                'Passwords Dont Match',
                'Given passwords dont match please check and try again',
                'warning'
            );
            return
        }
        if (ePassValid === false) {
            Swal.fire(
                'Weak Password',
                'Please make sure the entered password contains atleast 6 character long containing one Uppercase letter,one Lowercase letter,one Special character & one Number each',
                'warning'
            );
        }
        setButtonVisible(true);
        if (eValid === true && ePhoneValid === true && ePassValid === true && passMatch === true) {
            setButtonVisible(false);
            axios({
                method: 'POST',
                url: 'https://k-plus-api.herokuapp.com/auth/signup',
                // url: 'http://localhost:8000/auth/signup',
                data: {
                    "first_name": fname,
                    "last_name": lname,
                    "email": email,
                    "password": pass,
                    "phone": phone,
                    "dob": dob,
                    "location":address,
                }
            }).then(res => {
                setButtonVisible(true);
                if (res.data.token !== undefined) {
                    // We wont save the token here we will send user to login page to login and then we will save the token
                    Swal.fire({
                        title: 'Account Created Successfully',
                        text: `Welcome to K Plus ${fname} ${lname} 🎉`,
                        icon: 'success',
                    }).then(res =>{
                        console.log(JSON.stringify(res,0,4))
                        if (res.dismiss || res.isConfirmed || res.isDenied || res.isDismissed){
                            props.history.push('/login')
                        }
                    }).catch(err =>{
                        console.error(err);
                    })
                }
                if (res.data.message !== undefined) {
                    Swal.fire(
                        `Error Occured`,
                        `${res.data.message}`,
                        'error'
                    )
                    setButtonVisible(true);
                }
            }).catch(err => {
                console.log(err.status);
                console.log(err);
                Swal.fire(
                    'Error Occured',
                    `${err}`,
                    'error'
                )
                setButtonVisible(true);
            })
        }
    }

    return (
        <section id="signup">
            <div className="left">
                <div className="auth-container">
                    <div className="auth-card">
                        <h3 className="text-center">Register to <span className="blokk">K Plus</span></h3>
                        <br />
                        <div className={`zoom-out-signup-form ${index ? 'd-block' : 'd-none'}`}>
                            <div className="imput">
                                <span>First Name</span>
                                <input className="form-control" name="firstname" type="text" placeholder="Enter your first name" maxLength="50"
                                    onChange={(e) => setFname(e.target.value)}
                                />
                            </div>
                            <div className="imput">
                                <span>Last Name</span>
                                <input className="form-control" name="lastname" type="text" placeholder="Enter your last name" maxLength="50"
                                    onChange={(e) => setLname(e.target.value)}
                                />
                            </div>
                            <div className="imput">
                                <span>Date of Birth</span>
                                <div className="right-icon">
                                    <i className="fa fa-table mobi"></i>
                                    <input className="form-control" name="dob" type="date" placeholder="Enter your date of birth"
                                        onChange={(e) => setDob(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="imput">
                                <span>Address</span>
                                <input className="form-control" name="lastname" type="text" placeholder="Enter your address" maxLength="50"
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <button className="btn w-100 text-white mb-1" id="submit-signup" style={{ background: '#66f' }} onClick={() => setIndex(!index)}>Next</button>
                            <Link className="mt-3 d-block text-center" style={{textDecoration: 'none'}} to={"/login"}>Already a user?, Login here</Link>
                        </div>
                        <div className={`zoom-out-signup-form  ${index ? 'd-none' : 'd-block'}`}>
                            <button className="btn backbtn" onClick={() => setIndex(!index)}>
                                <i className="fa fa-arrow-left fs-4 text-white"></i>
                            </button>
                            <div className="imput">
                                <span>Email</span>
                                <div className="left-icon">
                                    <i className="fas fa-envelope"></i>
                                    <input className="form-control" name="email" type="email" placeholder="Enter your email address"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="imput">
                                <span>Phone number</span>
                                <div className="left-icon">
                                    <i className="fas fa-phone-alt"></i>
                                    <input className="form-control" name="phone" type="number" placeholder="Enter your 10 digit phone number"
                                        maxLength="13"
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="imput">
                                <span>Password</span>
                                <div className="right-icon">
                                    <i className={`fa fa ${showPass ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPass(!showPass)}></i>
                                    <input className="form-control" name="password" type={showPass ? 'text' : 'password'} placeholder="Enter password"
                                        onChange={(e) => setPass(e.target.value)}
                                        maxLength="20"
                                    />
                                </div>
                            </div>
                            <div className="imput">
                                <span>Confirm Password</span>
                                <div className="right-icon">
                                    <i className={`fa fa ${showCpass ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowCpass(!showCpass)}></i>
                                    <input className="form-control" name="confpassword" type={showCpass ? 'text' : 'password'} placeholder="Confirm password"
                                        onChange={(e) => setCpass(e.target.value)}
                                        maxLength="20"
                                    />
                                </div>
                            </div>
                            {buttonVisible ?
                                <button className="btn w-100 text-white mb-2" id="submit-signup" style={{ background: '#66f' }} onClick={formSubmit}>Register</button>
                                :
                                <button className="btn w-100 text-white mb-2" id="submit-signup" style={{background: 'rgba(164, 164, 255, 0.95)'}} onClick={() => console.log('Wait Bithc')}>
                                    <div className="spinner-border text-white" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="signup-footer py-2">
                    <Link to={'/privacy-policy'}>Privacy Policy</Link>
                    <Link to={'/terms-and-conditions'}>Terms and Conditions</Link>
                </div>
            </div>
            <div className="right">
                <div className="overlay">
                    <div className="hero">
                        <img src={require('../../assets/logo.jpg')} alt="K Plus logo" />
                        <h1>K Plus</h1>
                        <p>
                        K Plus is an educational institution established on 2nd April 2020 on an online basis. It started its first center on 24th January 2021 in Kolkata and gradually it expanded all over the country even overseas.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Signup
