import { useState, useEffect } from 'react'
import { useAuthDispatch, logout, useAuthState } from '../../Context';

function Dashboard(props) {
    console.log(props)
    const dispatch = useAuthDispatch() // read dispatch method from context to logout user from here
    const user = useAuthState() // read user details from context
    const [auth,setAuth] = useState()
    const [fname,setFname] = useState('')
    const [lname,setLname] = useState('')
    const [email,setEmail] = useState('')
    const [username,setUsername] = useState('')

	useEffect(() => {
        console.log('this function ran actually')
		setFname(user.userDetails.first_name)
        setLname(user.userDetails.last_name)
        setEmail(user.userDetails.email)
        setUsername(user.userDetails.username)
	}, [auth]);
    

    const handleLogout = () => {
        logout(dispatch) //call the logout action
        
        // This is not updating the compnoents, so even after user logs out his username is visible even though its not present
        // So for now window.location.hrf is being used
        // props.history.push('/login') //navigate to logout page on logout

        window.location.href = "/"
    }
    return (
        <div style={{ padding: 10 }}>
            <div >
                <h1>
                    Dashboard
                </h1>
                <div className="d-flex my-5">
                    <button className="btn btn-lg btn-danger d-block mx-3" onClick={handleLogout}>Logout</button>
                    <button className="btn btn-lg btn-success mx-3" onClick={() => props.history.push('/')}>Go To Home</button>
                </div>
            </div>

            <p className="fs-2 fw-bold">Welcome {fname} {lname} 🎉🎉🎉🎉🎉</p>
            <p className="fs-2 fw-light">Email : <strong>{email}</strong> </p>
            <p className="fs-2 fw-light">Phone no. : <strong>{username}</strong> </p>
        </div>
    )
}
 
export default Dashboard