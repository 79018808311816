import Homepage from "../pages/Homepage/Homepage";
import Login from '../pages/Login/Login';
import Signup from "../pages/Signup/Signup";
import Dashboard from "../pages/Dashboard";
import Notfound from "../pages/Notfound";
import FreeResources from "../pages/Freeresources";
import ComingSoon from "../pages/Comingsoon";

const routes = [
  {
    path: '/free-resources',
    component: FreeResources,
    isPrivate: false,
  },
  {
    path: '/coming-soon',
    component: ComingSoon,
    isPrivate: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: '/signup',
    component: Signup,
    isPrivate: false,
  },
  {
    path: '/login',
    component: Login,
    isPrivate: false,
  },
  {
    path: '/',
    component: Homepage,
    isPrivate: false,
  },
];


export default routes;