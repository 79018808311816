import { BrowserRouter as Router, Switch, Route  } from "react-router-dom";
import { AuthProvider } from './Context/context';
import AppRoute from "./AppRoute";
import routes from "./Config/routes";

function App() {
	return (
		<AuthProvider>
			<Router>
			<Switch>
				{routes.map((route) => (
					<AppRoute
					key={route.path}
					path={route.path}
					component={route.component}
					isPrivate={route.isPrivate}
					/>
				))}
				</Switch>
			</Router>
		</AuthProvider>
	);
}

export default App;
