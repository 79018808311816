const AboutUsCards = (props) => {
    return (
        <div className={`k-card ${props.bgc ? props.bgc : 'bg-warning'}`} data-aos={props.aosAnime} data-aos-duration={props.aosDuration}>
            <div className="img-container">
                <img src={require(`${props.img}`)} alt={props.title} />
            </div>
            <h3>{props.title}</h3>
            <p>{props.content}
            </p>
        </div>
    )
}

export default AboutUsCards
