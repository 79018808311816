import { useEffect } from 'react';
import AboutUsCards from './AboutUsCards'
import AOS from 'aos';
import 'aos/dist/aos.css';

require('./css/HPS2.css')

const HPS2 = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
    <section className="sectionhsc2" id="aboutus">
        <div className="circletl" data-aos="fade-right" data-aos-duration="1000" />
        <div className="circletrd" data-aos="fade-left" data-aos-duration="1000" />
        <div className='container hsc2' id="hsc2">
            <h1
                data-aos="fade-up"
                data-aos-duration="400"
            >Why <span>K Plus</span> Classes ?</h1>
            <div className="d-flex justify-content-center align-items-center">
                    <video
                        id="playpic" 
                        src={require('./assets/video/vid.webm')}
                        poster={require('./assets/video/thumb.jpeg')}
                        autoplay
                        alt="k plus video tutorials"
                        controls='true'
                        data-aos="zoom-in"
                        data-aos-duration="700" />
            </div>
            <article data-aos="fade-up">
            K Plus is an educational institution established on 2nd April 2020 on an online basis. It started its first center on 24th January 2021 in Kolkata and gradually it expanded all over the country even overseas. We provide educational services from K212 in three specialized formats. We follow T-P approach (Theory- Practical) and provide virtual+personalized mentorship. On 26th January 2022, we started our new venture for K25 called M&M (Mentor & Mentorship).
            </article>
            <div className="about-us-cards-container">
                <div className="hideOverflowOnMobile">
                    <AboutUsCards title="Small Batches" img="./assets/smallbatches.png" bgc="tomato" aosAnime="zoom-in-up" aosDuration="800" content="Small batches ensure that our students gets full attention of our faculty, so that no student is lagging behind in studies." />
                    <AboutUsCards title="Individual Doubt Clearing" img="./assets/doubtclear.png" bgc="blue" aosAnime="zoom-in-up" aosDuration="800" content="Individual doubt clearing sessions are conducted in every class so that your child has no doubts left over the subject." />
                    <AboutUsCards title="Stress free environment" img="./assets/stressfree.png" bgc="purple" aosAnime="zoom-in-up" aosDuration="800" content="Stress free environment is maintained in our classroom so that your child does not feels burdened by the academic pressure." />
                </div>
                <div className="hideOverflowOnMobile colrev">
                    <AboutUsCards title="Highly Experienced Faculty" img="./assets/experiencedteachers.png" bgc="pup"  aosAnime="zoom-in-up" aosDuration="800" content="Our highly experienced faculty will ensure in all round development of our students and motivate them to study harder." />
                    <AboutUsCards title="Focus on boards" img="./assets/boards.png" bgc="orange"  aosAnime="zoom-in-up" aosDuration="800" content="We specifically focus on ICSE/CBSE/State Board examinations so that our students score excellent in their board examinations." />
                    <AboutUsCards title="Competent study modules" img="./assets/reading.png" bgc="green"  aosAnime="zoom-in-up" aosDuration="800" content="We got competent study modules for our students from where they will learn and revise. Our short and crisp notes helps our students to score more in less time." />
                </div>
            </div>
        </div>
        <div className="circlebl" data-aos="fade-right" data-aos-duration="1000" />
        <div className="circlebr" data-aos="fade-left" data-aos-duration="1000" />
    </section>
    )
}

export default HPS2;