import { useEffect } from 'react';
import AOS from 'aos';

const ModesOfTeachingCards = (props) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className="k-mode" data-aos={props.dataAOS} data-aos-duration="800">
            <div className="img-container">
                <img src={require(`${props.img}`)} alt="" />
                {/* <img className="blob" src={require('./assets/blob.png')} alt="" /> */}
            </div>
            <div className="info-container">
                <h3>{props.title}</h3>
                <p>
                    {props.content}
                </p>
            </div>
        </div>
    )
}

export default ModesOfTeachingCards