import Navbar from '../components/Navbar/';
import Footer from '../components/Footer/';
require('./freeresources.css');

const FreeResources = () => {

	const  dumArray = [
    'Ejm4iBgkI-g',
    'JaW8hukXfJs',
    'RC3a4QeCMIc',
    '6OtfvvoIZVY',
    'CBmv1amt1Z4',
    'lsUWAaL-WD4',
    'nNmgP0CrIzQ',
    'apR9Nie1Rb0',
    'kJUOOjdYvTg',
    'XU1D7lZ8i4I'
  ]

  return <>
    <Navbar />
    <div className="hero-res">
      <div className="overlay">
        <h1>Free Resources</h1>
      </div>
    </div>
    <section className="content" id="freres">
      <div className="container">
        <h1>Video Lectures</h1>
		<div className="videos-container">
			{
			dumArray.map((data,index) =>{
				return(
				<div className="demo-item">
					<iframe height="280" src={`https://www.youtube.com/embed/${data}`} title="Tutorial" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
				</div>
			);})
			}
		</div>
		{/* <h1>Class Notes</h1> */}
      </div>
    </section>
    <Footer />
  </>;
};

export default FreeResources;
