import { Route,Redirect } from "react-router-dom";
 
import { useAuthState } from './Context'
 
const AppRoute = ({ component: Component, path, isPrivate, ...rest }) => {
    const userDetails = useAuthState() // Using this i can access user detail anywhere
    console.log(userDetails)
    return (
        <Route
            path={path}
            render={props =>
                isPrivate && !Boolean(userDetails.token) ? (
                    <Redirect
                        to={{ pathname: "/login" }}
                    />
                ) : (
                        <Component {...props} />
                    )
            }
            {...rest}
        />
    )
}
 
export default AppRoute