import { useEffect } from 'react';
import AOS from 'aos';
import './css/HPS3.css';
import { Link } from 'react-router-dom';

const HPS3 = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <section id="shsc3">
            <div className="container hsc3" id="hsc3">
                <div className="info-container">
                    <h1 data-aos="fade-up" data-aos-duration="600"><span>K Plus</span> Scholarship Test</h1>
                    <img className="my-3" data-aos="fade-up" data-aos-duration="600" src={require('./assets/scholarship.png')} alt="k plus scholarship exam" />
                    <p data-aos="fade-up" data-aos-duration="600">
                        K Plus Scholarship exam is for those underprivilged students who are meritorious and ambitous for their studies. This scholarship is funded by Krishna Foundation, which conducts many social events and activities throughout the year.
                    </p>
                    <div className="btn-container" data-aos="fade-up" data-aos-duration="500">
                        <Link to={"/coming-soon"} >Register For Test</Link>
                    </div>
                </div>
                <div className="img-container">
                    <img src={require('./assets/scholarship.png')} alt="k plus scholarship exam" data-aos="fade-left" data-aos-duration="500" />
                </div>
            </div>
        </section>
    )
}

export default HPS3
