

const SwiperSlide = () => {
    return (
        <div className="slide">
            Slide 1
        </div>
    )
}

export default SwiperSlide
