const Questions = [
    {
        "question" : "How to register for K Plus classes ?",
        "answer" : "You can try contacting us on our phone number for offline classes currently later this would be done online only."
    },
    {
        "question" : "How to book a demo class ?",
        "answer" : "First signup to K Plus and then from your Dashboard you will see a book a demo class option click on it and fill all the necessary fields. Then our staff will contact you regarding the class timing. This is not ready presently."
    },
    {
        "question" : "I want to know about offline class timings of K Plus.",
        "answer" : "You can try calling us on our phone number as of now to know more about our class timings for seperate batches."
    },
    {
        "question" : "How to buy a course from K Plus ?",
        "answer" :  "Headover to the Courses tab, then add a course to your cart, proceed to checkout and then finish the payment transactions, you will find your purchased courses in the tab section of your Dahboard. The Dashboard is still under development."
    },
];

export default Questions