import Navbar from "../components/Navbar"
import HPS1 from "./HomePageSections/HPS1"
import HPS2 from "./HomePageSections/HPS2"
import HPS3 from "./HomePageSections/HPS3"
import HPS4 from "./HomePageSections/HPS4"
import HPS5 from "./HomePageSections/HPS5"
import HPS6 from "./HomePageSections/HPS6"
import Faq from "./HomePageSections/Faq"
import SlideShow from "./HomePageSections/SlideShow"
import Gallery from "./HomePageSections/Gallery"
import Footer from "../components/Footer"
import { useEffect } from "react"

const Homepage = (props) => {


    useEffect(() =>{
        if(window.location.hash !== ''){
            window.scrollBy(0, window.innerHeight);
        }
    },[window.location.hash])
    return (
        <>
            <Navbar />
            <SlideShow />
            {/* <HPS1 /> */}
            <HPS2 />
            <HPS4 />
            <Gallery />
            <HPS6 />
            <HPS5 />
            <HPS3 />
            <Faq />
            <Footer />
        </>
    )
}

export default Homepage
